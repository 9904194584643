import React from 'react';

import s from './style.scss';

export type PropsType = {
    city: string;
    feelsLike: string;
    temp: string;
    pressure: string;
    wind: string;
    visibility: string;
};

const WeatherBLock = ({city, feelsLike, temp, pressure, wind, visibility}: PropsType) => (
    <div className={s.wrapperWeather} style={{height: '100%'}}>
        <p className={s.city}>Ваш город: {city}</p>
        <p className={s.temp}>
            Температура: <span className={s.value}>{temp} C&#176;</span>
        </p>
        <p className={s.tempLike}>
            Температура по ощущению: <span className={s.value}>{feelsLike} C&#176;</span>
        </p>
        <p className={s.pressure}>Атомосферное давление: {pressure} рт/с</p>
        <p className={s.pressure}>Ветер: {wind} м/с</p>
        <p className={s.pressure}>Видимость: {visibility}%</p>
    </div>
);

export default WeatherBLock;
