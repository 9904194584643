import axios from 'axios';

import {REQUEST_URL} from './constants';

export const instanceRequest = axios.create({
    baseURL: REQUEST_URL,
    params: {
        appid: process.env.WEATHER_TOKEN,
    },
});
