import React, {useEffect, useState} from 'react';

import {useDynamicScript} from '../../utils/useDynamicScript';

const loadComponent = async (scope: string, module: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await __webpack_init_sharing__('default');
    const container: any = window[scope as any];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await container.init(__webpack_share_scopes__.default);
    const factory = await container.get(module);
    const Module = factory();
    return Module;
};

type PropsType = {
    remoteAppInfo: {
        module: string;
        scope: string;
        url: string;
    };
    skeleton?: React.ReactElement | null;
    props?: any;
};

type RemoteModuleType = {
    mount: (args: any) => React.ReactElement;
};

/**
 * DynamicRemoteApp
 */
const DynamicRemoteApp: React.FC<PropsType> = ({
    remoteAppInfo: {module, scope, url},
    skeleton = null,
    props,
}): React.ReactElement | null => {
    const [remoteModule, setRemoteModule] = useState<null | RemoteModuleType>(null);
    const [component, setComponent] = useState<null | React.ReactElement>(null);
    const {ready} = useDynamicScript(url);

    useEffect(() => {
        const load = async () => {
            if (ready && !remoteModule) {
                const fetchedModule = await loadComponent(scope, module);
                setRemoteModule(fetchedModule);
            }
        };
        load();
    }, [remoteModule, ready, module, scope, props]);

    useEffect(() => {
        console.log('remoteModule: ', remoteModule);
        if (remoteModule) {
            setComponent(remoteModule.mount(props));
        }
    }, [remoteModule, props]);

    if (!component) return skeleton;

    return component;
};

export default DynamicRemoteApp;
