import {render} from 'react-dom';
import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';

import App from './containers/app';
import Theme from './context/themes';

import '@fontsource/manjari';
import '../css/style.scss';

render(
    <ErrorBoundary fallback={<div>Что-то пошло не так...</div>}>
        <Theme>
            <App />
        </Theme>
    </ErrorBoundary>,
    document.getElementById('root'),
);
