import React from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {HashRouter} from 'react-router-dom';
import {Provider} from 'react-redux';

import RouterComponent from '../router';
import store from '../../store';

const queryClient = new QueryClient();

const App = () => {
    return (
        <HashRouter>
            <QueryClientProvider client={queryClient}>
                <Provider store={store}>
                    <RouterComponent />
                </Provider>
            </QueryClientProvider>
        </HashRouter>
    );
};

export default App;
