import React, {useState} from 'react';
import {Button, Drawer} from 'antd';
import {Link} from 'react-router-dom';

import s from './style.scss';
import UserInfo from './components/UserInfo';

export const ToDo = ({
    children,
    isFulfilled,
}: {
    children?: React.ReactElement;
    isFulfilled: boolean;
}) => {
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <div className={s.container}>
            <>
                <Button type="primary" onClick={showDrawer}>
                    Открыть модалку
                </Button>
                <Drawer
                    title="Basic Drawer"
                    onClose={onClose}
                    open={open}
                    width={'90%'}
                    maskClosable={false}
                >
                    {children}
                </Drawer>
            </>
            <UserInfo />
            <div className={s.description}>
                Статус заполнения формы: <u>{String(isFulfilled)}</u>
            </div>
            <Link to="/" className={s.link}>
                Назад на главную
            </Link>
        </div>
    );
};
