import React, {useState} from 'react';
import {Routes, Route} from 'react-router-dom';
import {HomeConnected} from 'pages/Home';
import {Header} from 'components/header';
import {Footer} from 'components/footer';
import {connect, useDispatch, useSelector} from 'react-redux';
import {ToDo} from 'pages/ToDo';

import s from './style.scss';

import DynamicRemoteApp from '../dynamicRemoteApp/dynamicRemoteApp';
import {setFormAction} from '../../pages/Home/actions';
import {FormType} from '../../pages/ToDo/components/UserInfo/types';

const RouterComponent = () => {
    const [isFulfilled, setStatus] = useState<boolean>(false);
    const dispatch = useDispatch();

    const setForm = (form: FormType) => {
        dispatch(setFormAction(form));
    };

    return (
        <div className={s.container}>
            <Header />
            <div className={s.body}>
                <Routes>
                    <Route path="/" element={<HomeConnected />} />
                    <Route
                        path="/todo"
                        element={
                            <ToDo isFulfilled={isFulfilled}>
                                <DynamicRemoteApp
                                    skeleton={<div>ЖДИ НАХОЙ...</div>}
                                    remoteAppInfo={{
                                        module: './ButtonQuiz',
                                        scope: 'Quiz',
                                        url: `${
                                            process.env.NODE_ENV === 'production'
                                                ? 'https://frontstaging-s1.online'
                                                : 'http://localhost:9001'
                                        }/remoteEntry.js`,
                                    }}
                                    props={{
                                        setValueForm: setForm,
                                        isFulfilled: isFulfilled,
                                        setStatus: setStatus,
                                    }}
                                />
                            </ToDo>
                        }
                    />
                </Routes>
            </div>
            <Footer />
        </div>
    );
};

export default connect((store: any) => ({
    titleHeader: store.home.titleHeader,
    titleFooter: store.home.titleFooter,
}))(RouterComponent);
