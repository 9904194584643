import {FormType} from '../../ToDo/components/UserInfo/types';

export const setTitleAction = () => ({
    type: 'SET_TITLE',
});

export const setDescriptionAction = () => ({
    type: 'SET_DESCRIPTION',
});

export const setColorAction = (colorName: string) => ({
    type: 'SET_COLOR',
    colorName,
});

export const setFormAction = (action: FormType) => ({
    type: 'SET_FORM',
    form: action,
});
