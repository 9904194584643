import React from 'react';
import {useSelector} from 'react-redux';
import {Table} from 'antd';

import {FormType} from './types';
import styles from './style.scss';
import {columns, defaultValues} from './const';

const UserInfo: React.FC = () => {
    const form = useSelector(
        (state: {
            home: {
                form: FormType;
            };
        }) => state.home.form,
    );
    const formToArray = Object.entries(form).length
        ? Object.entries(form)
        : Object.entries(defaultValues);

    return (
        <Table
            columns={columns}
            dataSource={formToArray.map((item, index) => ({
                field: item[0],
                value: item[1],
                key: String(index),
            }))}
            pagination={false}
            className={styles.table}
        />
    );
};

export default UserInfo;
