import React from 'react';
// eslint-disable-next-line no-duplicate-imports
import type {TableProps} from 'antd';

import {DataType} from './types';

export const columns: TableProps<DataType>['columns'] = [
    {
        title: 'Название поля',
        dataIndex: 'field',
        key: 'field',
    },
    {
        title: 'Значение',
        dataIndex: 'value',
        key: 'value',
    },
];

export const defaultValues = {
    email: '-',
    password: '-',
    confirm: '-',
    nickname: '-',
    residence: [],
    prefix: '-',
    phone: '-',
    donation: 0,
    website: '-',
    intro: '-',
    gender: '-',
    agreement: false,
};
