import React from 'react';
import classnames from 'classnames';
import {connect} from 'react-redux';
import {useGeolocated} from 'react-geolocated';
import {useQuery} from 'react-query';
import {Link} from 'react-router-dom';

import {setColorAction, setDescriptionAction, setTitleAction} from './actions';
import s from './style.scss';
import WeatherBLock from './components/WeatherBlock';

import {instanceRequest} from '../../utils/api';
import {StorageKey, useTheme} from '../../context/themes';

export type PropsType = {
    setTitle: () => void;
    setDescription: () => void;
    description: string;
    color: string;
    setColor: (arg: string) => void;
};

const useFetchWeather = (coords: GeolocationCoordinates | undefined) => {
    const {data, isLoading} = useQuery(
        'weather_start',
        async () => {
            const {data: response} = await instanceRequest.get(
                `?lat=${coords?.latitude.toFixed(4)}&lon=${coords?.longitude.toFixed(
                    4,
                )}&units=metric`,
            );
            console.log('response: ', response);
            return response;
        },
        {
            notifyOnChangeProps: 'tracked',
            enabled: !!coords,
        },
    );

    return {data, isLoading};
};

export const Home = ({color}: PropsType) => {
    const {coords} = useGeolocated();
    const {data, isLoading} = useFetchWeather(coords);
    const {theme, setTheme} = useTheme();

    /**
     * Rounds value to an integer
     * @param {string} value - value
     * @return {string} new value
     */
    const roundValue = (value: string) => {
        const numberValue = Math.round(Number(value));
        return String(numberValue);
    };

    const setThemeCall = () => {
        setTheme(localStorage.getItem(StorageKey) === 'light' ? 'dark' : 'light');
    };

    return (
        <div className={classnames(s.wrapper, s[color])}>
            <button type="button" className={s.replaceTheme} onClick={setThemeCall}>
                Сменить тему
            </button>
            <h1 className={s.title}>{`FrontStand My site in ${theme} theme`}</h1>
            {!data || isLoading ? (
                <div style={{height: '100%'}}>Ожидание...</div>
            ) : (
                <WeatherBLock
                    city={data.name}
                    feelsLike={roundValue(data.main.feels_like)}
                    temp={roundValue(data.main.temp)}
                    pressure={data.main.pressure}
                    wind={data.wind.speed}
                    visibility={data.visibility}
                />
            )}
            <Link className={s.link} to="/todo">
                На другую страницу
            </Link>
        </div>
    );
};

export const HomeConnected = connect(
    (store: {home: {description: string; color: string}}) => ({
        description: store.home.description,
        color: store.home.color,
    }),
    {
        setColor: setColorAction,
        setTitle: setTitleAction,
        setDescription: setDescriptionAction,
    },
)(Home);
